import { useState, useEffect } from 'react';
import ProjectList from './ProjectList.js';
import ProjectDetails from './ProjectDetails.js';
import UserList from './UserList.js';
import UserDetails from './UserDetails.js';
import './MainWindow.css';

function MainWindow(props) {
    // define state for the variables that we use in the details view
    const [showContainer, setShowContainer] = useState('projects');
    const [phpVersions, updatePhpVersions] = useState('');
    const [defaultPhpVersion, updateDefaultPhpVersion] = useState('');
    const [projectInfoState, setProjectInfoState] = useState({
        id: '',
        name: '',
        fullname: '',
        url: '',
        login: '',
        password: '',
        phpversion: '',
        databases: [],
        databaseName: '',
        databaseUser: '',
        databasePassword: ''
    });
    const updateProjectInfoState = e => {
        setProjectInfoState({
            ...projectInfoState,
            [e.target.name]: e.target.value
        });
    }
    const [users, setUsers] = useState([]);
    const [userInfoState, setUserInfoState] = useState({
        id: '',
        name: '',
        fullname: '',
        password: ''
    });

    // functions to return JSX for projects and users containers
    const projectsContainer = () => {
        return(
            <div className="projects container">
                <ProjectList projectInfoState={projectInfoState} setProjectInfoState={setProjectInfoState} phpVersions={phpVersions} defaultPhpVersion={defaultPhpVersion} />
                <ProjectDetails projectInfoState={projectInfoState} setProjectInfoState={setProjectInfoState} updateProjectInfoState={updateProjectInfoState} phpVersions={phpVersions} />
            </div>
        );
    }

    const usersContainer = () => {
        return(
            <div className="users container">
                <UserList users={users} setUsers={setUsers} setUserInfoState={setUserInfoState} />
                <UserDetails users={users} setUsers={setUsers} userInfoState={userInfoState} setUserInfoState={setUserInfoState} />
            </div>
        );
    }

    // load the PHP versions from the server when this part of the application is loaded
    useEffect(() => {
        // get PHP versions and populate the phpVersions variable
        try {
            fetch('/api/v1/get_default_php_version', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                }
            }).then(response => response.json()).then(response => {
                updateDefaultPhpVersion(response.data);
            }).then(
                fetch('/api/v1/get_php_versions', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    }
                }).then(response => response.json()).then(response => {
                    if(response.status === 'ok') {
                        if(response.data.length > 0) {
                            updatePhpVersions(response.data.map((version) => {
                                return(
                                    <option key={version} value={version}>{version}</option>
                                );
                            }));
                        }
                        else {
                            console.log('No results sent from server');
                        }
                        
                    }
                    else {
                        console.log('Error response from server');
                    }
                })
            );
        }
        catch(ex) {
            console.log('Exception from server: %s', ex);
        }
    }, []);

    return(
        <div className="MainWindow">
            {props.isAuth === true &&
            <>
            <div className="tabs">
                <button className="projects tab" onClick={() => setShowContainer('projects')}>Projects</button>
                <button className="users tab" onClick={() => setShowContainer('users')}>Users</button>
            </div>
            {showContainer === 'projects' && projectsContainer()}
            {showContainer === 'users' && usersContainer()}
            </>}
        </div>
    );
}

export default MainWindow;